<template>
  <div class="view-login auth">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-5 col-xl-4 py-4">
          <form class="card border-0 shadow" @submit.prevent="submit">
            <div class="card-body p-4">
              <img class="icon" src="../assets/images/icon.png" />
              <img class="logo mb-4" src="../assets/images/logo-small.png" />

              <h6 class="pt-2 mb-4 text-center">Bem-vindo(a) de volta!</h6>

              <form-group
                v-model="email"
                id="email"
                type="email"
                placeholder="Digite o email cadastrado"
                :errors="errors.email"
              />

              <form-group
                v-model="password"
                id="password"
                type="password"
                placeholder="Digite a sua senha"
                :errors="errors.password"
              />

              <p class="text-right mb-0">
                <a
                  href="javascript:void"
                  @click="forgotDialog = true"
                  class="text-danger btn-forgot"
                >
                  Esqueceu?
                </a>
              </p>

              <div class="text-center pt-4">
                <button
                  class="btn btn-danger px-5"
                  type="submit"
                  :disabled="loading"
                >
                  <loading :show="loading">Entrar</loading>
                </button>
              </div>
            </div>
          </form>

          <!-- <p class="text-center mt-5">
            Ainda não é cadastrado?
            <router-link :to="{ name: 'register' }">Cadastre-se!</router-link>
          </p> -->
        </div>

        <div class="col-md-5 col-lg-7 offset-xl-1 py-4">
          <h4 class="mb-1">Ainda não é aluno Tentacle?</h4>
          <h6 class="text-secondary mb-4">
            São dezenas de cursos nas seguintes áreas:
          </h6>

          <div class="row mb-3">
            <div class="col-lg-4 col-6 py-2">
              <div class="badge badge-1">
                <img src="../assets/images/login/icon-1.png" />
                <strong>Idiomas</strong>
              </div>
            </div>
            <div class="col-lg-4 col-6 py-2">
              <div class="badge badge-2">
                <img src="../assets/images/login/icon-2.png" />
                <strong>Intercâmbio</strong>
              </div>
            </div>
            <div class="col-lg-4 col-6 py-2">
              <div class="badge badge-3">
                <img src="../assets/images/login/icon-3.png" />
                <strong>Bolsas de estudos</strong>
              </div>
            </div>
            <div class="col-lg-4 col-6 py-2">
              <div class="badge badge-4">
                <img src="../assets/images/login/icon-4.png" />
                <strong>Mentorias</strong>
              </div>
            </div>
            <div class="col-lg-4 col-6 py-2">
              <div class="badge badge-5">
                <img src="../assets/images/login/icon-5.png" />
                <strong>Negócios</strong>
              </div>
            </div>
            <div class="col-lg-4 col-6 py-2">
              <div class="badge badge-6">
                <img src="../assets/images/login/icon-6.png" />
                <strong>Testes e provas</strong>
              </div>
            </div>
          </div>

          <div class="card subscription">
            <div class="card-body d-flex align-items-center p-4">
              <img class="mr-lg-4" src="../assets/images/login/rocket.png" />
              <h4 class="my-3 my-lg-0">
                Comece a fazer sua carreira decolar hoje!
              </h4>
              <div>
                <a
                  href="https://pay.hotmart.com/G38291166U?off=5lyjf2r7&checkoutMode=10&bid=1687367510117"
                  class="btn btn-success"
                  >Matricule-se</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <forgot-password v-model="forgotDialog" />
  </div>
</template>

<script>
import ForgotPassword from "@/components/login/ForgotPassword";

export default {
  name: "login",
  components: {
    ForgotPassword
  },
  data() {
    return {
      email: "",
      password: "",
      errors: {
        email: null,
        password: null,
        non_field_errors: null
      },
      loading: false,
      forgotDialog: false
    };
  },
  methods: {
    submit() {
      this.$message.hide();

      this.loading = true;

      this.$store
        .dispatch("user/login", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.$store.dispatch("postLogin").then(() => {
            if (this.$user.user && !this.$user.user.is_enable) {
              this.$router.replace({ name: "sale" });
            }
          });
          this.$router.replace("/");
        })
        .catch(error => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-login {
  width: 100%;

  form {
    padding: 8px 0;
  }

  .logo {
    max-width: 90px;
  }

  .icon,
  .logo {
    display: block;
    margin: 0 auto;
  }

  .btn-forgot {
    min-height: 0;
    padding: 0;
    font-weight: bold;
    font-size: 12px;
  }

  .badge {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    padding: 32px 0 24px;

    img {
      margin-bottom: 17px;
    }

    strong {
      font-size: 14px;
      line-height: 21px;
      color: white;
    }

    &.badge-1 {
      background: #d797ff;
    }
    &.badge-2 {
      background: #f88c2a;
    }
    &.badge-3 {
      background: #feca57;
    }
    &.badge-4 {
      background: #54a0ff;
    }
    &.badge-5 {
      background: #ef4d57;
    }
    &.badge-6 {
      background: #9cd33a;
    }
  }

  .subscription {
    background: rgba(200, 214, 229, 0.2);
    border: none;

    .card-body {
      @media screen and (max-width: 991px) {
        flex-direction: column;
        text-align: center;
      }
    }

    h4 {
      color: #576574;
    }

    button {
      white-space: nowrap;
      font-family: "DM Sans", sans-serif;
      font-weight: bold;
      padding-right: 32px;
      padding-left: 32px;
    }
  }

  a.btn {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
}
</style>
